<template>
  <base-section id="features" class="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col v-for="card in cards" :key="card.title" cols="12" sm="4" md="3">
            <base-info-card align="center" dark v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatures",

  data: () => ({
    cards: [
      {
        icon: "mdi-location-enter",
        title: "Geofencing",
        text: "Create Custom Geofences to Track Vehicles More Effciently"
      },
      {
        icon: "mdi-coolant-temperature",
        title: "Temperature Sensor",
        text: "Track Cold storage temperature readings Online. "
      },
      {
        icon: "mdi-pencil-outline",
        title: "Brand Making",
        text: "Partner with FleeTOP for Whilte Labelling,    Custom Design"
      },
      {
        icon: "mdi-phone",
        title: "24/7 Support",
        text:
          "24/7 Cusotmer Care 6364887210/11/12 to address any field issues immediately"
      }
    ]
  })
};
</script>
